import { createRouter, createWebHistory } from 'vue-router'
import { useSessionStore } from "@/stores/_sessionStore.js";

const routes = [
  {
    path: '/login',
    name: 'Inici de sessió',
    component: function () {
      return import('../views/public/login/LoginView.vue')
    },
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    name: 'Inici',
    component: function () {
      return import('../views/public/inicio/InicioView.vue')
    },
    children: [
      {
        path: '/',
        name: 'victimas',
        component: function () {
          return import('@/components/victimas/VictimasComponent.vue')
        },
        meta: { requiresAuth: false }
      },
      {
        path: '/ajustes',
        name: 'ajustes',
        component: function () {
          return import('../views/admin/ajustes/AjustesView.vue')
        },
        meta: { requiresAuth: true }
      },
      {
        path: '/usuaris',
        name: 'usuaris',
        component: function () {
          return import('../views/admin/usuarios/UsuariosView.vue')
        },
        meta: { requiresAuth: true }
      },
      {
        path: '/perfil',
        name: 'perfil',
        component: function () {
          return import('../views/admin/perfil/perfilView.vue')
        },
        meta: { requiresAuth: true }
      },
      {
        path: '/editar-victima/:id',
        name: 'editarvictima',
        component: function () {
          return import('@/views/admin/editar-victima/EditarVictimaView.vue')
        },
        meta: { requiresAuth: true }
      },
      {
        path: '/victima/:id',
        name: 'victima',
        component: function () {
          return import('@/views/public/victima/VictimaView.vue')
        },
        meta: { requiresAuth: false }
      },      
      {
        path: '/victima-camps/:id',
        name: 'victimacamps',
        component: function () {
          return import('@/views/public/victima-campos/VictimaCamposView.vue')
        },
        meta: { requiresAuth: false }
      }      

    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router


//Cada vez que se cargue una ruta se accederá a esta fn
router.beforeEach((to, from, next) => {
  const sessionStore = useSessionStore();

  //Guardamos en una variable si se debe estar autenticado para acceder a la página
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  //Guardamos en una variable si está autenticado
  const isAuthenticated = sessionStore.session


  //Si se requiere autenticación y no está iniciada sesión
  if (requiresAuth && !isAuthenticated) {
    next('/login') //Redirecciona a login

    //Si está autenticado y se intenta acceder a login
  } else if (isAuthenticated && to.fullPath == "/login") {
    next('/') //redirecciona a la página principal
    
    //Si un usuario con el rol 2(editor) intenta acceder a usuarios
  } else if (isAuthenticated && to.fullPath == "/usuaris" && isAuthenticated.user.rol == 2) {
    next('/') //redirecciona a la página principal
  
  } else {
    next() // Si no se cumple ninguna de las anteriores entonces se podrá acceder donde se pretendía
  }
})
