import { defineStore } from 'pinia'
import { useCookies } from "vue3-cookies";
//import router from '@/router'

const { cookies } = useCookies();

import axios from "axios";
import ajustesAPI from "./_ajustesStore.js";
const baseUrl = ajustesAPI.url


export const useSessionStore = defineStore("sessionStore", {
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        // user: cookies.get("session")),
        session: cookies.get("session"),
        returnUrl: null
    }),

    actions: {
        async login(mail, pass) {
            let result;
            let error;
            await axios
                .post(baseUrl + "login/", {
                    email: mail,
                    password: pass,
                })
                .then((res) => {
                    cookies.set("session", JSON.stringify(res.data));
                    location.reload();
                })
                .catch((err) => {
                    error = err
                });

            return { result, error };

        },
        async checkPass(pass) {
            let result;
            let error;

            await axios
                .post(baseUrl + "login/", {
                    email: this.session.user.email,
                    password: pass,
                })
                .then((res) => {
                    result = res
                })
                .catch((err) => {
                    error = err
                });

            return { result, error };

        },
        logout() {
            cookies.remove("session")
            location.reload();
        }
    },
});
